/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7;
  $on('pageInit', function () {
    getCURPS();
    getUNIDADES();
    $("select[name=idunidad]").change(function () {
      $("#inmueble").val(document.getElementById('idunidad').selectedOptions[0].dataset.inmueble);
      $("#idinmueble").val(document.getElementById('idunidad').selectedOptions[0].dataset.idinmueble);
      $("#precioregular").val(document.getElementById('idunidad').selectedOptions[0].dataset.marketrent);
      $("#curp_owner").val(document.getElementById('idunidad').selectedOptions[0].dataset.curp_owner);
      var pp = document.getElementById('idunidad').selectedOptions[0].dataset.prontopago;

      if (pp == "null") {
        pp = document.getElementById('idunidad').selectedOptions[0].dataset.marketrent;
      } else {
        pp = document.getElementById('idunidad').selectedOptions[0].dataset.prontopago;
      }

      $("#prontopago").val(pp);
      $("#deposito").val(document.getElementById('idunidad').selectedOptions[0].dataset.deposito);
    });
    $('[name=radio_descuento]').on('click', function (event) {
      var formulario = $f7.form.convertToData('#my-form');
      var formData = [];
      var bonificacion = this.value;
      var prontopago = formulario.prontopago;

      if (bonificacion > 0) {
        var descuento = bonificacion * prontopago / 30.4; //var descuento = (this.value * $('[name=precioregular]').value)/30.4;

        formData.descuento = Math.floor(descuento);
      } else {
        // es 0 y no hace descuento
        formData.descuento = bonificacion;
      }

      $f7.form.fillFromData('#my-form', formData);
    }); //inicializa el select de curps

    function getCURPS() {
      var formData = [];
      formData.operacion = 'get';
      formData.combo = 'addcontrato.f7.html_comboCurp'; //return false;

      $f7.dialog.progress();
      $f7.request.get('https://7perp.geopanda.com.mx/php/admCombos.7perp.php', formData, "json").then(function (res) {
        if (res.data.code == 200) {
          //construyo el select
          res.data.elementos.forEach(function (element) {
            var div = document.getElementById(element.grupo);
            div.innerHTML += '<option value="' + element.curp + '">' + element.nombre + '</option>';
          });
        }

        $f7.dialog.close(); //$f7.dialog.alert(res.data.msj);
        //limpiaForm();
      }).catch(function (err) {
        $f7.dialog.close();
        $f7.dialog.alert(err.statusText);
        console.log(err.xhr);
        console.log(err.status);
        console.log(err.message);
      }).finally(function (e) {//limpiaForm();
      });
    }

    ; //inicializa el select de unidades

    function getUNIDADES() {
      //gruposUnidad
      var formData = [];
      formData.operacion = 'get';
      formData.combo = 'addcontrato.f7.html_comboUnidades'; //return false;

      $f7.dialog.progress();
      $f7.request.get('https://7perp.geopanda.com.mx/php/admCombos.7perp.php', formData, "json").then(function (res) {
        if (res.data.code == 200) {
          //construyo el select
          console.log(res.data.elementos);
          var optgroup = 0;
          var div2 = document.getElementById('idunidad');
          var cadena = '<option value="0" selected disabled></option>';
          var i = 0;
          var total = res.data.elementos.length;
          console.log(total);
          res.data.elementos.forEach(function (element) {
            /* var div = document.getElementById(element.grupo);
             div.innerHTML += '<option value="' + element.idunidad + '" data-marketRent=' + element.marketRent + ' data-prontopago=' + element.prontopago + ' data-idinmueble=' + element.idinmueble + ' data-curp_owner="'+element.curp_owner+'" data-inmueble="' + element.inmueble + '" data-unidadtxt="' + element.alias + '">' + element.alias + '</option>';*/
            if (optgroup == 0) {
              cadena += '<optgroup label ="' + element.inmueble + '">';
              optgroup = element.idinmueble;
            } else if (element.idinmueble != optgroup) {
              cadena += '</optgroup><optgroup label ="' + element.inmueble + '">';
              optgroup = element.idinmueble;
            }

            cadena += '<option value="' + element.idunidad + '" data-marketRent=' + element.marketRent + ' data-prontopago=' + element.prontopago + ' data-idinmueble=' + element.idinmueble + ' data-curp_owner="' + element.curp_owner + '" data-inmueble="' + element.inmueble + '" data-unidadtxt="' + element.alias + '" data-deposito="' + element.deposito + '">' + element.alias + '</option>';
            i++; //incrementa contador, si es el ultimo reg, que cierre el optgroup

            if (i >= total) {
              cadena += '</optgroup>';
              div2.innerHTML = cadena;
            }
          });
        }

        $f7.dialog.close(); //$f7.dialog.alert(res.data.msj);
        //limpiaForm();
      }).catch(function (err) {
        $f7.dialog.close();
        $f7.dialog.alert(err.statusText);
        console.log(err.xhr);
        console.log(err.status);
        console.log(err.message);
      }).finally(function (e) {//limpiaForm();
      });
    }

    ; //SET INICIAL DEL CALENDARIO

    var calendarDefault = $f7.calendar.create({
      inputEl: '#startdate',
      //dateFormat: { month: '2-digit', day: '2-digit', year: 'numeric' },
      dateFormat: 'yyyy-mm-dd',
      // minDate: new Date(),
      openIn: 'customModal',
      header: true,
      footer: true
    });
    calendarDefault.setValue([new Date()]);
    var calendarDefaultDiscount = $f7.calendar.create({
      inputEl: '#discountdate',
      //dateFormat: { month: '2-digit', day: '2-digit', year: 'numeric' },
      dateFormat: 'yyyy-mm-dd',
      openIn: 'customModal',
      header: true,
      footer: true
    });
    calendarDefaultDiscount.setValue([new Date()]);
    $('.convert-form-to-data').on('click', function () {
      var formData = $f7.form.convertToData('#my-form');
      formData.unidadtxt = document.getElementById('idunidad').selectedOptions[0].dataset.unidadtxt;
      formData.operacion = 'post';
      console.log(formData); //return false;
      //enviar a guardar

      $f7.dialog.progress();
      $f7.request.post('https://7perp.geopanda.com.mx/php/adminLeases.7perp.php', formData, "json") //$f7.request.post('http://localhost/geo007erp/src/php/adminLeases.7perp.php', formData, "json")
      .then(function (res) {
        console.log(res.data); //reibe el json de respuesta

        $f7.dialog.close();
        $f7.dialog.alert(res.data.msj); //limpiaForm();
      }).catch(function (err) {
        $f7.dialog.close();
        $f7.dialog.alert(err.statusText);
        console.log(err.xhr);
        console.log(err.status);
        console.log(err.message);
      }).finally(function (e) {//limpiaForm();
      });
    });

    function limpiaForm() {
      var formData = {
        'curp': '',
        'motivo': '',
        'externo': ['no']
      };
      $f7.form.fillFromData('#my-form', formData);
    }

    $('.fill-form-from-data').on('click', function () {
      var formData = {
        'curp': 'John',
        'motivo': 'john@doe.com',
        'externo': ['yes']
      };
      $f7.form.fillFromData('#my-form', formData);
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="addblacklist">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title">NUEVO CONTRATO</div>
      </div>
    </div>
    <div class="page-content">
     
      <div class="block">
        
        <form class="list no-store-data" id="my-form">
          <ul>

            <li>
              <a href="#" class="item-link smart-select smart-select-init" data-close-on-select="true" data-page-title="Inquilinos seleccionables" data-searchbar="true" data-searchbar-placeholder="Busqueda">
                <!-- "maxlength" attribute to limit number of possible selected items -->
                <!-- we won't allow to select more than 3 items -->
                <select name="curp">
                  <option value="" selected disabled></option>
                  <optgroup label="RECIEN AGREGADO" id="nuevo">
                  </optgroup>
                  <optgroup label="CON CONTRATO VIGENTE" id="vigente">
                  </optgroup>
                  <optgroup label="ES ANTIGUO INQUILINO" id="antiguo">
                  </optgroup>
                  <optgroup label="PROPIETARIOS" id="propietarios">
                  </optgroup>
                  <optgroup label="EN LISTA NEGRA" id="blacklist">
                  </optgroup>
                </select>
                <div class="item-content">
                  <div class="item-media"><i class="material-icons size-29">person</i></div>
                  <div class="item-inner">
                    <div class="item-title">INQUILINO</div>
                  </div>
                </div>
              </a>
            </li>

            <!--<li>
              <a href="#" class="item-link smart-select smart-select-init" data-close-on-select="true" data-page-title="Unidades disponibles" data-searchbar="true" data-searchbar-placeholder="Busqueda">
              
                <select name="idunidad_" id="idunidad_">
                  <option value="" selected disabled></option>
                  <optgroup label="UNIDADES DISPONIBLES" id="disponible">
                  </optgroup>
                  <optgroup label="CON CONTRATO VIGENTE" id="ocupado">
                  </optgroup>
                  <optgroup label="UNIDADES INACTIVAS" id="inactivo">
                  </optgroup>

                </select>


                  

                <div class="item-content">
                  <div class="item-media"><i class="material-icons size-29">airline_seat_individual_suite</i></div>
                  <div class="item-inner">
                    <div class="item-title">UNIDAD</div>
                  </div>
                </div>
              </a>
            </li>-->


            <li>
              <a href="#" class="item-link smart-select smart-select-init" data-close-on-select="true" data-page-title="Unidades disponibles" data-searchbar="true" data-searchbar-placeholder="Busqueda">
                <!-- "maxlength" attribute to limit number of possible selected items -->
                <!-- we won't allow to select more than 3 items -->
                <select name="idunidad" id="idunidad">
               
                </select>

                <div class="item-content">
                  <div class="item-media"><i class="material-icons size-29">airline_seat_individual_suite</i></div>
                  <div class="item-inner">
                    <div class="item-title">UNIDAD</div>
                  </div>
                </div>
              </a>
            </li>

            <li>
              <div class="item-content item-input disabled">
                <div class="item-media"><i class="material-icons size-29">home</i></div>
                <div class="item-inner">
                  <div class="item-title item-label">INMUEBLE</div>
                  <div class="item-input-wrap">
                    <input type="text" class="display-none" name="idinmueble" id="idinmueble" placeholder="INMUEBLE"  />
                    <input type="text" disabled  name="inmueble" id="inmueble" placeholder="INMUEBLE"  />
                  </div>
                </div>
              </div>
            </li>
            
            <li>
              <div class="item-content item-input disabled">
                <div class="item-media"><i class="material-icons size-29">vpn_key</i></div>
                <div class="item-inner">
                  <div class="item-title item-label">PROPIETARIO</div>
                  <div class="item-input-wrap">
                    <input type="text" disabled  name="curp_owner" id="curp_owner" placeholder="PROPIETARIO"  />
                  </div>
                </div>
              </div>
            </li>


            <li>
              <div class="item-content item-input">
                <div class="item-media"><i class="material-icons size-29">shopping_cart</i></div>
                <div class="item-inner">
                  <div class="item-title item-label">PRECIO REGULAR</div>
                  <div class="item-input-wrap">
                    <input type="number" name="precioregular" id="precioregular" placeholder="PRECIO REGULAR" pattern="[0-9]*" step="50"/>
                     <span class="input-clear-button"></span>
                  </div>
                </div>
              </div>
            </li>
            
            <li>
              <div class="item-content item-input">
                <div class="item-media"><i class="material-icons size-29">remove_shopping_cart</i></div>
                <div class="item-inner">
                  <div class="item-title item-label">PRECIO PRONTO PAGO</div>
                  <div class="item-input-wrap">
                    <input type="number" name="prontopago" id="prontopago" placeholder="PRONTO PAGO"  pattern="[0-9]*" step="50"/>
                     <span class="input-clear-button"></span>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <div class="item-content item-input">
                <div class="item-media"><i class="material-icons size-29">lock</i></div>
                <div class="item-inner">
                  <div class="item-title item-label">DEPOSITO EN GARANTIA</div>
                  <div class="item-input-wrap">
                    <input type="number" name="deposito" id="deposito" placeholder="DEPOSITO"  pattern="[0-9]*" value="0" step="50"/>
                     <span class="input-clear-button"></span>
                  </div>
                </div>
              </div>
            </li>

         



  <div class="block-header text-align-center margin-top">¿Aplicar bonificación en la próxima mensualidad?</div>
   
   
        <li>
          <label class="item-radio item-radio-icon-end item-content">
            <input type="radio" name="radio_descuento" value="14" checked />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title">14 días de bonificación</div>
            </div>
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-end item-content">
            <input type="radio" name="radio_descuento" value="10" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title">10 días de bonificación</div>
            </div>
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-end item-content">
            <input type="radio" name="radio_descuento" value="5" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title">5 días de bonificación</div>
            </div>
          </label>
        </li>
      
        <li>
          <label class="item-radio item-radio-icon-end item-content">
            <input type="radio" name="radio_descuento" value="0" checked />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title">Sin bonificación</div>
            </div>
          </label>
        </li>
   

            
           <li>
              <div class="item-content item-input">
                <div class="item-media"><i class="material-icons size-29">local_offer</i></div>
                <div class="item-inner">
                  <div class="item-title item-label">DESCUENTO PARA EL PROXIMO MES</div>
                  <div class="item-input-wrap">
                    <input type="number" name="descuento" id="descuento" placeholder="DESCUENTO"  pattern="[0-9]*" value="0" step="50"/>
                     <span class="input-clear-button"></span>
                  </div>
                </div>              
              </div>
           </li>
          
          
            <li>
              <div class="item-content item-input">
                <div class="item-media"><i class="material-icons size-29">today</i></div>
                <div class="item-inner">
                  <div class="item-title item-label">FECHA ENTREGA DE LLAVES</div>
                  <div class="item-input-wrap">
                    <input type="text" name="startdate" id="startdate" placeholder="YYYY-MM-DD"  />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </div>
            </li>


            <!--<li>
              <div class="item-content">
                <div class="item-media"><i class="material-icons size-29">fingerprint</i></div>
                <div class="item-inner">
                  <div class="item-title">Firmar Digitalmente</div>
                  <div class="item-after">
                    <a class="button button-fill display-none" id="btn_firma" href="/signature/" data-view=".view-main">Contratos</a>
                    <label class="toggle toggle-init">
                      <input class="no-store-data" type="checkbox" name="firmardigital" id="firmardigital" /><i class="toggle-icon"></i>
                    </label>
                  </div>
                </div>
              </div>
            </li>-->




            

            <div class="block block-strong row">
                  <div class="col"><a class="button button-fill convert-form-to-data" href="#">GENERAR CONTRATO</a></div>
            </div>

          </ul>
        </form>

     


      </div>

    </div>

  </div>
`
    }
    ;
}

framework7Component.id = '2eade21b7e';
export default framework7Component;