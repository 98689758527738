/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $update = _ref.$update;
  // Login screen demo data
  var username = '';
  var password = '';
  var selected = 'home';
  var selectedMedia = 'home';

  var updateUsername = function updateUsername(e) {
    username = e.target.value;
    $update();
  };

  var updatePassword = function updatePassword(e) {
    password = e.target.value;
    $update();
  };

  var alertLoginData = function alertLoginData() {
    $f7.dialog.alert('Username: ' + username + '<br/>Password: ' + password, function () {
      $f7.loginScreen.close();
    });
  };

  var setItem = function setItem(newItem) {
    selected = newItem;
    $update();
  };

  var setItemMedia = function setItemMedia(newItem) {
    selectedMedia = newItem;
    $update();
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app">


    <!-- Left panel with cover effect -->
    <div class="panel panel-left panel-cover theme-dark panel-init" data-visible-breakpoint="2048">
      <div class="view view-init" data-name="left">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
              <div class="title">Operación</div>
            </div>
          </div>
          <div class="page-content">


            <div class="list menu-list">
              <ul>
                <li>
                  <a href="/personas/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">person</i>
                      <i class="icon f7-icons if-not-md">person</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Personas</div>
                    </div>
                  </a>
                </li>
               <!-- <li>
                  <a href="/contratosList/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">business</i>
                      <i class="icon f7-icons if-not-md">person_fill</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Inmuebles</div>
                    </div>
                  </a>
                </li> -->
                <li>
                  <a href="/contratosList/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">receipt_long</i>
                      <i class="icon f7-icons if-not-md">receipt_long</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Contratos</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/pagos/" data-view=".view-main" data-force="true" data-ignore-cache="true" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">payment</i>
                      <i class="icon f7-icons if-not-md">payment</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Pagos</div>
                    </div>
                  </a>
                </li>
                <!--<li>
                  <a href="/contratosList/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">report_problem</i>
                      <i class="icon f7-icons if-not-md">wallet</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Incidentes</div>
                    </div>
                  </a>
                </li>-->
                <li>
                  <a href="/about/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">event_seat</i>
                      <i class="icon f7-icons if-not-md">wallet</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Acerca de</div>
                    </div>
                  </a>
                </li>

              </ul>
            </div>

          
          </div>
        </div>
      </div>
    </div>

    <!-- Right panel with reveal effect-->
    <div class="panel panel-right panel-reveal theme-dark">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Administración</div>
            </div>
          </div>
          <div class="page-content">
         
            <div class="list menu-list">
              <ul>
                <li>
                  <a href="/accountingDashboard/" data-view=".view-main" data-force="true" data-ignore-cache="true" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">account_balance</i>
                      <i class="icon f7-icons if-not-md">wallet</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Contabilidad</div>
                    </div>
                  </a>
                </li>
                <!--<li>
                  <a href="/personas/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">person</i>
                      <i class="icon f7-icons if-not-md">person_fill</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Personas</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/contratosList/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">business</i>
                      <i class="icon f7-icons if-not-md">person_fill</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Inmuebles</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/contratosList/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">receipt_long</i>
                      <i class="icon f7-icons if-not-md">person_fill</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Contratos</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/contratosList/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">account_balance</i>
                      <i class="icon f7-icons if-not-md">wallet</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Contabilidad</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/contratosList/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">report_problem</i>
                      <i class="icon f7-icons if-not-md">wallet</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Incidentes</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/about/" data-view=".view-main" class="item-content panel-close item-link">
                    <div class="item-media">
                      <i class="icon material-icons if-md">event_seat</i>
                      <i class="icon f7-icons if-not-md">wallet</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title">Acerca de</div>
                    </div>
                  </a>
                </li>-->

              </ul>
            </div>
         
            
          </div>
        </div>
      </div>
    </div>

    <!-- Your main view, should have "view-main" class -->
    <div class="view view-main view-init safe-areas" data-url="/"></div>


    <!-- Popup -->
    <div class="popup" id="my-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Popup</div>
              <div class="right">
                <a href="#" class="link popup-close">Close</a>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">
              <p>Popup content goes here.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Login Screen -->
    <div class="login-screen" id="my-login-screen">
      <div class="view">
        <div class="page">
          <div class="page-content login-screen-content">
            <div class="login-screen-title">Login</div>
            <div class="list">
              <ul>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">Username</div>
                    <div class="item-input-wrap">
                      <input type="text" name="username" placeholder="Your username" value="${username}" @input="${updateUsername}"/>

                    </div>
                  </div>
                </li>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">Password</div>
                    <div class="item-input-wrap">
                      <input type="password" name="password" placeholder="Your password" value="${password}" @input="${updatePassword}"/>

                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="list">
              <ul>
                <li>
                  <a href="#" class="item-link list-button login-button" @click="${alertLoginData}">Sign In</a>

                </li>
              </ul>
              <div class="block-footer">Some text about login information.<br/>Click "Sign In" to close Login Screen</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
`
    }
    ;
}

framework7Component.id = '8b52fd47af';
export default framework7Component;