/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component() {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
<div class="page" data-name="blacklist">
  <div class="navbar">
    <div class="navbar-bg"></div>
    <div class="navbar-inner sliding">
      <div class="left">
        <a href="#" class="link back">
          <i class="icon icon-back"></i>
          <span class="if-not-md">Back</span>
        </a>
      </div>
      <div class="title">Lista Contratos</div>
    </div>
  </div>
  <div class="page-content">
    <div class="block-title">Descripción</div>
    <div class="block block-strong">
      <p>Listado de Contratos vigentes y vencidos desplegados en 2 tablas</p>

      <div class="list">
        <!-- First group-->
        <div class="list-group">
          <ul>
            <li class="list-group-title">Contratos Vigentes</li>
            <li class="item-content">No pago de la renta (morosos)</li>
            <li class="item-content">Infringir reglamento</li>
            <li class="item-content">Falta de higiene</li>
            <li class="item-content">Discriminación</li>
            <li class="item-content">Violencia</li>
          </ul>
        </div>
      
        <!-- Second group-->
        <div class="list-group">
          <ul>
            <li class="list-group-title">Contratos Vencidos</li>
            <li class="item-content">Deudores de prestamos</li>
            <li class="item-content">Inquilinos que quedaron mal con otros arrendadores</li>
            
          </ul>
        </div>

        <div class="list-group">
          <ul>
            <li class="list-group-title">Contratos Terminados</li>
            <li class="item-content">Deudores de prestamos</li>
            <li class="item-content">Inquilinos que quedaron mal con otros arrendadores</li>
            
          </ul>
        </div>

      </div>
    </div>
    
  </div>

  <div class="fab fab-right-bottom">
    <a href="/addcontrato/" data-view=".view-main">
      <i class="icon f7-icons">plus</i>
    </a>
  </div>

</div>
`
    }
    ;
}

framework7Component.id = '74c4dcd643';
export default framework7Component;