/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7;
  $on('pageInit', function () {
    var paises_renapo_json = require("../js/cat_paises_renapo.json"); // guardar persona


    $('.convert-form-to-data').on('click', function () {
      var formData = $f7.form.convertToData('#my-form');
      formData.operacion = 'post';
      formData.genero = formData.genero.length == 1 ? 'M' : 'H';
      formData.verifica_blacklist = formData.blacklist.length; // formData.verifica_ine = formData.ine.length;

      formData.verifica_renapo = formData.renapo.length;
      formData.entidad_nac_cod = getTextForm("entidad_nac", "data-entidad_nac_cod", "select");
      formData.entidad_nac_txt = getTextForm("entidad_nac", "", "select_");
      formData.firma = signaturePad.isEmpty() ? null : signaturePad.toDataURL('image/png'); //formData.firma = signaturePad.toDataURL('image/png');

      formData.fechNac = formData.fecha_nac_anio + '-' + formData.fecha_nac_mes + '-' + formData.fecha_nac_dia;
      formData._x = Math.random();
      console.log(formData); //return false;

      $f7.dialog.progress();
      $f7.request.post('https://7perp.geopanda.com.mx/php/adminPersonas.7perp.php', formData, "json").then(function (res) {
        console.log(res.data); //reibe el json de respuesta

        $f7.dialog.close();
        var notificationClickToClose = $f7.notification.create({
          icon: '<i class="icon material-icons">check_circle_outline</i> ',
          title: ' 7P',
          titleRightText: 'ahora',
          subtitle: 'Septimo Piso',
          text: res.data.msj,
          closeOnClick: true
        });
        notificationClickToClose.open(); //$f7.dialog.alert(res.data.msj);
        //limpia el formulario
        // $('.clean-data').click();
        // regresa la pantalla

        $(".link.back").click();
      }).catch(function (err) {
        $f7.dialog.close();
        $f7.dialog.alert(err.statusText);
        console.log(err.xhr);
        console.log(err.status);
        console.log(err.message);
      }).finally(function (e) {//limpiaForm();
      });
    }); //limpia el formulario

    $('.clean-data').on('click', function () {
      var formData = {
        'curp': '',
        'renapo': [''],
        'ine': [''],
        'blacklist': [''],
        'nombre': '',
        'paterno': '',
        'materno': '',
        'entidad_nac': '00',
        'fecha_nac_dia': '',
        'fecha_nac_mes': '',
        'fecha_nac_anio': '',
        'nacionalidad_cve': '',
        'nacionalidad': '',
        'celular': '',
        'cel_whatsapp': [''],
        'whatsapp': '',
        'email': '' //'genero': ['yes'],

      };
      $f7.form.fillFromData('#my-form', formData);
    }); //limpia el signature

    $('.clean-signature').on('click', function (event) {
      event.preventDefault();
      signaturePad.clear();
    });
    /* $("#curp").on('change', function () {
       //en cuanto cambie la curp apaga este elemento
       $('#renapo').prop("checked", false);
       $('#blacklist').prop("checked", false);
       $("#txt_lista_negra").removeClass("display-block").addClass("display-none");
     });*/

    $('.ine_a').on('click', function () {
      event.preventDefault();
      $("#ine_a").click();
    });
    $('.ine_b').on('click', function () {
      event.preventDefault();
      $("#ine_b").click();
    });
    $('#ine_a').on('change', function (element) {
      var formData = {}; //console.log(element);

      var file = element.srcElement.files[0];
      var reader = new FileReader();

      reader.onloadend = function () {
        formData.ine_a_txt = reader.result;
        $f7.form.fillFromData('#my-form', formData); //console.log('RESULT', reader.result);
      };

      var result = reader.readAsDataURL(file);
    });
    $('#ine_b').on('change', function (element) {
      var formData = {}; //console.log(element);

      var file = element.srcElement.files[0];
      var reader = new FileReader();

      reader.onloadend = function () {
        formData.ine_b_txt = reader.result;
        $f7.form.fillFromData('#my-form', formData); //console.log('RESULT', reader.result);
      };

      var result = reader.readAsDataURL(file);
    });
    $('#cel_whatsapp').on('change', function () {
      var formData = {};
      var cel = $("#celular").val();

      if (this.checked) {
        formData.whatsapp = cel;
        $f7.form.fillFromData('#my-form', formData);
      } else {
        formData.whatsapp = '';
        $f7.form.fillFromData('#my-form', formData);
      }
    });
    $('#renapo').on('change', function () {
      if (this.checked) {
        var curp2validate = $("#curp").val().toUpperCase();
        console.log("comienza la validación con renapo " + curp2validate);

        if (curpValida(curp2validate)) {
          //envio a validar a renapo
          $f7.dialog.progress();
          $f7.request.get('https://7perp.geopanda.com.mx/php/renapo_inegi.php', {
            cveCurp: curp2validate
          }, "json") //$f7.request.get('http://localhost/geo007erp/src/php/renapo.php', { cveCurp: curp2validate }, "json")
          .then(function (res) {
            console.log(res);
            console.log(res.data);
            $f7.dialog.close();

            if (res.status == 200) {
              //leno el formulario con la informacion rescatada de renapo
              if (res.data.sexo == "M") {
                $('#genero').prop("checked", true);
              } else {
                $('#genero').prop("checked", false);
              }

              var fechaNac = '00/00/0000';
              fechaNac = res.data.fechNac.split("/"); //nacionalidad

              var nacionalidad = res.data.cveEntidadNac;

              for (var i = 0; i < paises_renapo_json.length; i++) {
                if (paises_renapo_json[i].clave_nacionalidad.toLowerCase().indexOf(res.data.nacionalidad.toLowerCase()) === 0) {
                  nacionalidad = paises_renapo_json[i].pais;
                  break;
                }
              }

              var formData = {
                'nombre': res.data.nombres,
                'paterno': res.data.apellido1,
                'materno': res.data.apellido2,
                'entidad_nac': res.data.cveEntidadNac,
                'fecha_nac_dia': fechaNac[0],
                'fecha_nac_mes': fechaNac[1],
                'fecha_nac_anio': fechaNac[2],
                'nacionalidad_cve': res.data.nacionalidad,
                'nacionalidad': nacionalidad //'genero': ['yes'],

              };
              $f7.form.fillFromData('#my-form', formData);
              $f7.dialog.close();
            } else {
              $('#renapo').prop("checked", false);
              $f7.dialog.close();
              $f7.dialog.alert(res.data.msj);
            }
          }).catch(function (err) {
            $f7.dialog.close();
            console.log(err.xhr);
            console.log(err.status);
            console.log(err.message);
          });
          console.log("curp valida");
        } else {
          $('#renapo').prop("checked", false);
          $f7.dialog.close();
          $f7.dialog.alert("CURP NO VÁLIDA");
        }
      }

      this.checked ? console.log("1") : console.log("0");
    }); //revisa en blacklist

    $('#blacklist').on('change', function () {
      if (this.checked) {
        console.log("revisa en black list");
        var formData = [];
        formData.operacion = 'get';
        formData.curp = $("#curp").val().toUpperCase();
        console.log(formData);
        $f7.dialog.progress();
        $f7.request.get('https://7perp.geopanda.com.mx/php/adminBlackList.7perp.php', formData, "json").then(function (res) {
          $f7.dialog.close();

          if (res.data.code == 200) {
            //si esta en lista negra
            $f7.dialog.alert("Este usuario cuenta con un REPORTE");
            $("#txt_lista_negra").removeClass("display-none").addClass("display-block");
            $(".titulo_lista_negra").text("REPORTE");
            $(".texto_lista_negra").text("Este usuario tiene un reporte por el siguiente motivo: " + res.data.elementos[0].motivo);
            console.log(res.data); //reibe el json de respuesta
          } else if (res.data.code == 201) {
            //no esta en lista negra
            //el usuario no esta en la lista prieta
            $f7.dialog.alert("USUARIO SIN REPORTE EN LISTA NEGRA");
          } else if (res.data.code == 400) {
            $f7.dialog.close();
            $f7.dialog.alert(res.data.msj);
          }
        }).catch(function (err) {
          $f7.dialog.close();
          $f7.dialog.alert(err.statusText);
          console.log(err.xhr);
          console.log(err.status);
          console.log(err.message);
        }).finally(function (e) {//limpiaForm();
        });
      } else {
        $("#txt_lista_negra").removeClass("display-block").addClass("display-none");
      }
    });

    function limpiaForm() {
      var formData = {
        'curp': '',
        'motivo': '',
        'externo': ['no']
      };
      $f7.form.fillFromData('#my-form', formData);
    } //Función para validar una CURP


    function curpValida(curp) {
      var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          validado = curp.match(re);
      if (!validado) //Coincide con el formato general?
        return false; //Validar que coincida el dígito verificador

      function digitoVerificador(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
            lngSuma = 0.0,
            lngDigito = 0.0;

        for (var i = 0; i < 17; i++) {
          lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        }

        lngDigito = 10 - lngSuma % 10;
        if (lngDigito == 10) return 0;
        return lngDigito;
      }

      if (validado[2] != digitoVerificador(validado[1])) return false;
      return true; //Validado
    }

    function getTextForm(dataName, dataAtribute, objeto) {
      var collection = document.getElementsByName(dataName);

      switch (objeto) {
        case "radio":
          for (var i = 0; i < collection.length; i++) {
            if (collection[i].checked == true) {
              return collection[i].getAttribute(dataAtribute);
            }
          }

          break;

        case "select":
          return collection[0].options[collection[0].selectedIndex].getAttribute(dataAtribute);
          break;

        case "select_":
          console.log(collection);
          return collection[0].options[collection[0].selectedIndex].text; //  console.log(this.options[this.selectedIndex].text);

          break;
      }
    } //signature


    var SignaturePad = function (document) {
      "use strict";

      var log = console.log.bind(console);

      var SignaturePad = function SignaturePad(canvas, options) {
        var self = this,
            opts = options || {};
        this.velocityFilterWeight = opts.velocityFilterWeight || 0.7;
        this.minWidth = opts.minWidth || 0.5;
        this.maxWidth = opts.maxWidth || 2.5;

        this.dotSize = opts.dotSize || function () {
          return (self.minWidth + self.maxWidth) / 2;
        };

        this.penColor = opts.penColor || "black";
        this.backgroundColor = opts.backgroundColor || "rgba(0,0,0,0)";
        this.throttle = opts.throttle || 0;
        this.throttleOptions = {
          leading: true,
          trailing: true
        };
        this.minPointDistance = opts.minPointDistance || 0;
        this.onEnd = opts.onEnd;
        this.onBegin = opts.onBegin;
        this._canvas = canvas;
        this._ctx = canvas.getContext("2d");
        this._ctx.lineCap = 'round';
        this.clear(); // we need add these inline so they are available to unbind while still having
        //  access to 'self' we could use _.bind but it's not worth adding a dependency

        this._handleMouseDown = function (event) {
          if (event.which === 1) {
            self._mouseButtonDown = true;

            self._strokeBegin(event);
          }
        };

        var _handleMouseMove = function _handleMouseMove(event) {
          event.preventDefault();

          if (self._mouseButtonDown) {
            self._strokeUpdate(event);

            if (self.arePointsDisplayed) {
              var point = self._createPoint(event);

              self._drawMark(point.x, point.y, 5);
            }
          }
        }; //this._handleMouseMove = _.throttle(_handleMouseMove, self.throttle, self.throttleOptions);


        this._handleMouseMove = _handleMouseMove;

        this._handleMouseUp = function (event) {
          if (event.which === 1 && self._mouseButtonDown) {
            self._mouseButtonDown = false;

            self._strokeEnd(event);
          }
        };

        this._handleTouchStart = function (event) {
          if (event.targetTouches.length == 1) {
            var touch = event.changedTouches[0];

            self._strokeBegin(touch);
          }
        };

        var _handleTouchMove = function _handleTouchMove(event) {
          // Prevent scrolling.
          event.preventDefault();
          var touch = event.targetTouches[0];

          self._strokeUpdate(touch);

          if (self.arePointsDisplayed) {
            var point = self._createPoint(touch);

            self._drawMark(point.x, point.y, 5);
          }
        }; //this._handleTouchMove = _.throttle(_handleTouchMove, self.throttle, self.throttleOptions);


        this._handleTouchMove = _handleTouchMove;

        this._handleTouchEnd = function (event) {
          var wasCanvasTouched = event.target === self._canvas;

          if (wasCanvasTouched) {
            event.preventDefault();

            self._strokeEnd(event);
          }
        };

        this._handleMouseEvents();

        this._handleTouchEvents();
      };

      SignaturePad.prototype.clear = function () {
        var ctx = this._ctx,
            canvas = this._canvas;
        ctx.fillStyle = this.backgroundColor;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        this._reset();
      };

      SignaturePad.prototype.showPointsToggle = function () {
        this.arePointsDisplayed = !this.arePointsDisplayed;
      };

      SignaturePad.prototype.toDataURL = function (imageType, quality) {
        var canvas = this._canvas;
        console.log(canvas);
        return canvas.toDataURL.apply(canvas, arguments);
      };

      SignaturePad.prototype.fromDataURL = function (dataUrl) {
        var self = this,
            image = new Image(),
            ratio = window.devicePixelRatio || 1,
            width = this._canvas.width / ratio,
            height = this._canvas.height / ratio;

        this._reset();

        image.src = dataUrl;

        image.onload = function () {
          self._ctx.drawImage(image, 0, 0, width, height);
        };

        this._isEmpty = false;
      };

      SignaturePad.prototype._strokeUpdate = function (event) {
        var point = this._createPoint(event);

        if (this._isPointToBeUsed(point)) {
          this._addPoint(point);
        }
      };

      var pointsSkippedFromBeingAdded = 0;

      SignaturePad.prototype._isPointToBeUsed = function (point) {
        // Simplifying, De-noise
        if (!this.minPointDistance) return true;
        var points = this.points;

        if (points && points.length) {
          var lastPoint = points[points.length - 1];

          if (point.distanceTo(lastPoint) < this.minPointDistance) {
            // log(++pointsSkippedFromBeingAdded);
            return false;
          }
        }

        return true;
      };

      SignaturePad.prototype._strokeBegin = function (event) {
        this._reset();

        this._strokeUpdate(event);

        if (typeof this.onBegin === 'function') {
          this.onBegin(event);
        }
      };

      SignaturePad.prototype._strokeDraw = function (point) {
        var ctx = this._ctx,
            dotSize = typeof this.dotSize === 'function' ? this.dotSize() : this.dotSize;
        ctx.beginPath();

        this._drawPoint(point.x, point.y, dotSize);

        ctx.closePath();
        ctx.fill();
      };

      SignaturePad.prototype._strokeEnd = function (event) {
        var canDrawCurve = this.points.length > 2,
            point = this.points[0];

        if (!canDrawCurve && point) {
          this._strokeDraw(point);
        }

        if (typeof this.onEnd === 'function') {
          this.onEnd(event);
        }
      };

      SignaturePad.prototype._handleMouseEvents = function () {
        this._mouseButtonDown = false;

        this._canvas.addEventListener("mousedown", this._handleMouseDown);

        this._canvas.addEventListener("mousemove", this._handleMouseMove);

        document.addEventListener("mouseup", this._handleMouseUp);
      };

      SignaturePad.prototype._handleTouchEvents = function () {
        // Pass touch events to canvas element on mobile IE11 and Edge.
        this._canvas.style.msTouchAction = 'none';
        this._canvas.style.touchAction = 'none';

        this._canvas.addEventListener("touchstart", this._handleTouchStart);

        this._canvas.addEventListener("touchmove", this._handleTouchMove);

        this._canvas.addEventListener("touchend", this._handleTouchEnd);
      };

      SignaturePad.prototype.on = function () {
        this._handleMouseEvents();

        this._handleTouchEvents();
      };

      SignaturePad.prototype.off = function () {
        this._canvas.removeEventListener("mousedown", this._handleMouseDown);

        this._canvas.removeEventListener("mousemove", this._handleMouseMove);

        document.removeEventListener("mouseup", this._handleMouseUp);

        this._canvas.removeEventListener("touchstart", this._handleTouchStart);

        this._canvas.removeEventListener("touchmove", this._handleTouchMove);

        this._canvas.removeEventListener("touchend", this._handleTouchEnd);
      };

      SignaturePad.prototype.isEmpty = function () {
        return this._isEmpty;
      };

      SignaturePad.prototype._reset = function () {
        this.points = [];
        this._lastVelocity = 0;
        this._lastWidth = (this.minWidth + this.maxWidth) / 2;
        this._isEmpty = true;
        this._ctx.fillStyle = this.penColor;
      };

      SignaturePad.prototype._createPoint = function (event) {
        var rect = this._canvas.getBoundingClientRect();

        return new Point(event.clientX - rect.left, event.clientY - rect.top);
      };

      SignaturePad.prototype._addPoint = function (point) {
        var points = this.points,
            c2,
            c3,
            curve,
            tmp;
        points.push(point);

        if (points.length > 2) {
          // To reduce the initial lag make it work with 3 points
          // by copying the first point to the beginning.
          if (points.length === 3) points.unshift(points[0]);
          tmp = this._calculateCurveControlPoints(points[0], points[1], points[2]);
          c2 = tmp.c2;
          tmp = this._calculateCurveControlPoints(points[1], points[2], points[3]);
          c3 = tmp.c1;
          curve = new Bezier(points[1], c2, c3, points[2]);

          this._addCurve(curve); // Remove the first element from the list,
          // so that we always have no more than 4 points in points array.


          points.shift();
        }
      };

      SignaturePad.prototype._calculateCurveControlPoints = function (s1, s2, s3) {
        var dx1 = s1.x - s2.x,
            dy1 = s1.y - s2.y,
            dx2 = s2.x - s3.x,
            dy2 = s2.y - s3.y,
            m1 = {
          x: (s1.x + s2.x) / 2.0,
          y: (s1.y + s2.y) / 2.0
        },
            m2 = {
          x: (s2.x + s3.x) / 2.0,
          y: (s2.y + s3.y) / 2.0
        },
            l1 = Math.sqrt(1.0 * dx1 * dx1 + dy1 * dy1),
            l2 = Math.sqrt(1.0 * dx2 * dx2 + dy2 * dy2),
            dxm = m1.x - m2.x,
            dym = m1.y - m2.y,
            k = l2 / (l1 + l2),
            cm = {
          x: m2.x + dxm * k,
          y: m2.y + dym * k
        },
            tx = s2.x - cm.x,
            ty = s2.y - cm.y;
        return {
          c1: new Point(m1.x + tx, m1.y + ty),
          c2: new Point(m2.x + tx, m2.y + ty)
        };
      };

      SignaturePad.prototype._addCurve = function (curve) {
        var startPoint = curve.startPoint,
            endPoint = curve.endPoint,
            velocity,
            newWidth;
        velocity = endPoint.velocityFrom(startPoint);
        velocity = this.velocityFilterWeight * velocity + (1 - this.velocityFilterWeight) * this._lastVelocity;
        newWidth = this._strokeWidth(velocity);

        this._drawCurve(curve, this._lastWidth, newWidth);

        this._lastVelocity = velocity;
        this._lastWidth = newWidth;
      };

      SignaturePad.prototype._drawPoint = function (x, y, size) {
        var ctx = this._ctx;
        ctx.moveTo(x, y);
        ctx.arc(x, y, size, 0, 2 * Math.PI, false);
        this._isEmpty = false;
      };

      SignaturePad.prototype._drawMark = function (x, y, size) {
        var ctx = this._ctx;
        ctx.save();
        ctx.moveTo(x, y);
        ctx.arc(x, y, size, 0, 2 * Math.PI, false);
        ctx.fillStyle = 'rgba(255, 0, 0, 0.2)';
        ctx.fill();
        ctx.restore();
      };

      SignaturePad.prototype._drawCurve = function (curve, startWidth, endWidth) {
        var ctx = this._ctx,
            widthDelta = endWidth - startWidth,
            drawSteps,
            width,
            i,
            t,
            tt,
            ttt,
            u,
            uu,
            uuu,
            x,
            y;
        drawSteps = Math.floor(curve.length());
        ctx.beginPath();

        for (i = 0; i < drawSteps; i++) {
          // Calculate the Bezier (x, y) coordinate for this step.
          t = i / drawSteps;
          tt = t * t;
          ttt = tt * t;
          u = 1 - t;
          uu = u * u;
          uuu = uu * u;
          x = uuu * curve.startPoint.x;
          x += 3 * uu * t * curve.control1.x;
          x += 3 * u * tt * curve.control2.x;
          x += ttt * curve.endPoint.x;
          y = uuu * curve.startPoint.y;
          y += 3 * uu * t * curve.control1.y;
          y += 3 * u * tt * curve.control2.y;
          y += ttt * curve.endPoint.y;
          width = startWidth + ttt * widthDelta;

          this._drawPoint(x, y, width);
        }

        ctx.closePath();
        ctx.fill();
      };

      SignaturePad.prototype._strokeWidth = function (velocity) {
        return Math.max(this.maxWidth / (velocity + 1), this.minWidth);
      };

      var Point = function Point(x, y, time) {
        this.x = x;
        this.y = y;
        this.time = time || new Date().getTime();
      };

      Point.prototype.velocityFrom = function (start) {
        return this.time !== start.time ? this.distanceTo(start) / (this.time - start.time) : 1;
      };

      Point.prototype.distanceTo = function (start) {
        return Math.sqrt(Math.pow(this.x - start.x, 2) + Math.pow(this.y - start.y, 2));
      };

      var Bezier = function Bezier(startPoint, control1, control2, endPoint) {
        this.startPoint = startPoint;
        this.control1 = control1;
        this.control2 = control2;
        this.endPoint = endPoint;
      }; // Returns approximated length.


      Bezier.prototype.length = function () {
        var steps = 10,
            length = 0,
            i,
            t,
            cx,
            cy,
            px,
            py,
            xdiff,
            ydiff;

        for (i = 0; i <= steps; i++) {
          t = i / steps;
          cx = this._point(t, this.startPoint.x, this.control1.x, this.control2.x, this.endPoint.x);
          cy = this._point(t, this.startPoint.y, this.control1.y, this.control2.y, this.endPoint.y);

          if (i > 0) {
            xdiff = cx - px;
            ydiff = cy - py;
            length += Math.sqrt(xdiff * xdiff + ydiff * ydiff);
          }

          px = cx;
          py = cy;
        }

        return length;
      };

      Bezier.prototype._point = function (t, start, c1, c2, end) {
        return start * (1.0 - t) * (1.0 - t) * (1.0 - t) + 3.0 * c1 * (1.0 - t) * (1.0 - t) * t + 3.0 * c2 * (1.0 - t) * t * t + end * t * t * t;
      };

      return SignaturePad;
    }(document);

    var signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(25, 118, 210)',
      velocityFilterWeight: .7,
      minWidth: 0.5,
      maxWidth: 2.5,
      throttle: 16,
      // max x milli seconds on event update, OBS! this introduces lag for event update
      minPointDistance: 3
    });
    var saveButton = document.getElementById('save'),
        // clearButton = document.getElementById('clear'),
    showPointsToggle = document.getElementById('showPointsToggle');
    saveButton.addEventListener('click', function (event) {
      event.preventDefault();
      var data = signaturePad.toDataURL('image/png');
      console.log(data); // guarda la firma en una variable de sesion para guardarla mas tarde
      //sessionStorage.setItem("firma", data);
      // signaturePad_copia.fromDataURL(data);
      //$(".link.back").click();
      //window.open(data);
    });
    /*clearButton.addEventListener('click', function (event) {
      signaturePad.clear();
    });*/

    showPointsToggle.addEventListener('click', function (event) {
      signaturePad.showPointsToggle();
      showPointsToggle.classList.toggle('toggle');
    });

    function encodeImageFileAsURL(element) {
      //console.log(element);
      var file = element.files[0];
      var reader = new FileReader();

      reader.onloadend = function () {
        console.log('RESULT', reader.result);
        return reader.result;
      };

      var result = reader.readAsDataURL(file);
      console.log('result to return-> ' + result);
      return result;
    }
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="addblacklist">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title">Agregar persona</div>
      </div>
    </div>
    <div class="page-content">
      <div class="block-title text-align-center">Persona</div>
      <div class="block">
        <p class="text-align-center">Por favor llene todo el formulario</p>
        <form class="list no-store-data" id="my-form">
          <ul>



            <!--comenzamos con la captura de la INE-->
          <!--  <li>
              <p class="item-title text-align-center"> INE </p>
            </li>
            <div class="row">
              <div class="col-30">
                <button class="button button-fill color-pink ine_a " type='file'><i
                    class="icon material-icons">portrait</i>
                </button>
              </div>
              <div class="col-70">
                <input type="text" name="ine_a_txt" placeholder="INE A" />
                <span class="input-clear-button"></span>
              </div>
            </div>


            <div class="row margin-top">
              <div class="col-30">
                <button class="col button button-fill color-pink ine_b" type='file'><i
                    class="icon material-icons">credit_card</i>
                </button>
              </div>
              <div class="col-70">
                <input type="text" name="ine_b_txt" placeholder="INE B" />
                <span class="input-clear-button"></span>
              </div>
            </div>



            <div class="row">
              <input type="file" accept="image/*" capture="camera" id='ine_a' />
              <input class="col" type='file' id='ine_a_'  capture="camera" />
              <input class="col " type='file' id='ine_b' capture="camera"/>

            </div>-->

            <li class="margin-top">
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">CURP</div>
                  <div class="item-input-wrap">
                    <input type="text" name="curp" id="curp" placeholder="CURP" required validate
                      style="text-transform:uppercase" data-error-message="Completa este campo" />
                    <span class="input-clear-button clean-data"></span>
                  </div>
                </div>
              </div>
            </li>




            <div class="row">
              <div class="col-100 medium-50">
                <li>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">RENAPO</div>
                      <div class="item-after">
                        <label class="toggle toggle-init">
                          <input type="checkbox" name="renapo" id="renapo" value="no" /><i class="toggle-icon"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div class="col-100 medium-50">
                <li>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">LISTA NEGRA</div>
                      <div class="item-after">
                        <label class="toggle toggle-init">
                          <input type="checkbox" name="blacklist" id="blacklist" value="no" /><i
                            class="toggle-icon"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </div>


            </div>





            <li class="display-none" id="txt_lista_negra">
              <div class="block-title block-title-medium text-color-pink text-align-center titulo_lista_negra ">Block
                Title Medium</div>
              <div class="block block-strong bg-color-pink text-color-white text-align-justify texto_lista_negra">
                <p>Donec et nulla auctor massa pharetra adipiscing ut sit amet sem. Suspendisse molestie velit vitae
                  mattis
                  tincidunt. Ut sit amet quam mollis, vulputate turpis vel, sagittis felis. </p>
              </div>
            </li>








            <div class="row">
              <div class="col-100 medium-33">
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">NOMBRE(S)</div>
                      <div class="item-input-wrap">
                        <input type="text" name="nombre" placeholder="NOMBRE(S)" required validate
                          style="text-transform:uppercase" data-error-message="Completa este campo" />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div class="col-100 medium-33">
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">PATERNO</div>
                      <div class="item-input-wrap">
                        <input type="text" name="paterno" placeholder="APELLIDO PATERNO" required validate
                          style="text-transform:uppercase" data-error-message="Completa este campo" />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div class="col-100 medium-33">
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">MATERNO</div>
                      <div class="item-input-wrap">
                        <input type="text" name="materno" placeholder="APELLIDO MATERNO" required validate
                          style="text-transform:uppercase" data-error-message="Completa este campo" />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>

            <li>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title">¿Es MUJER?</div>
                  <div class="item-after">
                    <label class="toggle toggle-init color-pink">
                      <input type="checkbox" name="genero" id="genero" value="no" /><i class="toggle-icon"></i>
                    </label>
                  </div>
                </div>
              </div>
            </li>



            <div class="row">
              <div class="item-title item-label text-align-center">FECHA DE NACIMIENTO</div>
              <div class="col-33">
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">DIA</div>
                      <div class="item-input-wrap">
                        <input type="text" name="fecha_nac_dia" placeholder="DIA" />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div class="col-33">
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">MES</div>
                      <div class="item-input-wrap">
                        <input type="text" name="fecha_nac_mes" placeholder="MES" />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div class="col-33">
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">AÑO</div>
                      <div class="item-input-wrap">
                        <input type="text" name="fecha_nac_anio" placeholder="AÑO" />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>




            <div class="row">
              <div class="item-title item-label text-align-center">
                <p><i class="icon material-icons">flag</i></p>
                <p>ORIGEN</p>
              </div>
              <div class="col-100 bg-color-gray-claro">
                <!--LUGAR DE NACIMIENTO-->
                <li>
                  <a class="item-link smart-select smart-select-init" data-close-on-select="true">
                    <select name="entidad_nac" id="entidad_nac">
                      <option value="00" selected disabled data-entidad_cod="00">SELECCIONE ENTIDAD</option>
                      <option value="AS" data-entidad_nac_cod="01">AGUASCALIENTES</option>
                      <option value="BC" data-entidad_nac_cod="02">BAJA CALIFORNIA</option>
                      <option value="BS" data-entidad_nac_cod="03">BAJA CALIFORNIA SUR</option>
                      <option value="CC" data-entidad_nac_cod="04">CAMPECHE</option>
                      <option value="CL" data-entidad_nac_cod="05">COAHUILA</option>
                      <option value="CM" data-entidad_nac_cod="06">COLIMA</option>
                      <option value="CS" data-entidad_nac_cod="07">CHIAPAS</option>
                      <option value="CH" data-entidad_nac_cod="08">CHIHUAHUA</option>
                      <option value="DF" data-entidad_nac_cod="09">CDMX</option>
                      <option value="DG" data-entidad_nac_cod="10">DURANGO</option>
                      <option value="GT" data-entidad_nac_cod="11">GUANAJUATO</option>
                      <option value="GR" data-entidad_nac_cod="12">GUERRERO</option>
                      <option value="HG" data-entidad_nac_cod="13">HIDALGO</option>
                      <option value="JC" data-entidad_nac_cod="14">JALISCO</option>
                      <option value="MC" data-entidad_nac_cod="15">EDO DE MÉXICO</option>
                      <option value="MN" data-entidad_nac_cod="16">MICHOACÁN</option>
                      <option value="MS" data-entidad_nac_cod="17">MORELOS</option>
                      <option value="NT" data-entidad_nac_cod="18">NAYARIT</option>
                      <option value="NL" data-entidad_nac_cod="19">NUEVO LEÓN</option>
                      <option value="OC" data-entidad_nac_cod="20">OAXACA</option>
                      <option value="PL" data-entidad_nac_cod="21">PUEBLA</option>
                      <option value="QT" data-entidad_nac_cod="22">QUERÉTARO</option>
                      <option value="QR" data-entidad_nac_cod="23">QUINTANA ROO</option>
                      <option value="SP" data-entidad_nac_cod="24">SAN LUIS POTOSÍ</option>
                      <option value="SL" data-entidad_nac_cod="25">SINALOA</option>
                      <option value="SR" data-entidad_nac_cod="26">SONORA</option>
                      <option value="TC" data-entidad_nac_cod="27">TABASCO</option>
                      <option value="TS" data-entidad_nac_cod="28">TAMAULIPAS</option>
                      <option value="TL" data-entidad_nac_cod="29">TLAXCALA</option>
                      <option value="VZ" data-entidad_nac_cod="30">VERACRUZ</option>
                      <option value="YN" data-entidad_nac_cod="31">YUCATÁN</option>
                      <option value="ZS" data-entidad_nac_cod="32">ZACATECAS</option>
                      <option value="NE" data-entidad_nac_cod="99">NACIDO EN EL EXTRANJERO</option>
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title">LUGAR DE NACIMIENTO</div>
                      </div>
                    </div>
                  </a>
                </li>
              </div>
              <div class="col-100 bg-color-gray-claro">
                <div class="row">
                  <div class="col-33">
                    <li>
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">CLAVE</div>
                          <div class="item-input-wrap">
                            <input type="text" name="nacionalidad_cve" placeholder="CVE PAIS" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </div>
                    </li>

                  </div>
                  <div class="col-66">
                    <li>
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">NACIONALIDAD</div>
                          <div class="item-input-wrap">
                            <input type="text" name="nacionalidad" placeholder="NACIONALIDAD" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </div>
                    </li>

                  </div>
                </div>
              </div>

            </div>






            <div class="row">
              <div class="col-100 medium-33">
                <!-- CELULAR y EMAIL-->
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">CELULAR</div>
                      <div class="item-input-wrap">
                        <input type="text" name="celular" id="celular" placeholder="CELULAR" required validate
                          pattern="[0-9]*" data-error-message="Solo numeros" />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div class="col-100 medium-33">
                <li>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">CEL = WHATSAPP</div>
                      <div class="item-after">
                        <label class="toggle toggle-init color-green">
                          <input type="checkbox" name="cel_whatsapp" id="cel_whatsapp" value="no" /><i
                            class="toggle-icon"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div class="col-100 medium-33">
                <li>
                  <div class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">WHATSAPP</div>
                      <div class="item-input-wrap">
                        <input type="text" name="whatsapp" id="whatsapp" placeholder="WHATSAPP" required validate
                          pattern="[0-9]*" data-error-message="Solo numeros" />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>








            <li>
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">EMAIL</div>
                  <div class="item-input-wrap">
                    <input type="email" name="email" placeholder="EMAIL" required validate
                      style="text-transform:uppercase" data-error-message="Completa este campo" value="1@1.com" />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </div>
            </li>

            <div class="title item-label text-align-center">
              <p><i class="icon material-icons">fingerprint</i></p> FIRMA DIGITAL
            </div>


            <div class="row margin-top">
              <div class="col-100 medium-66">
                <div class="block text-align-center">
                  <p>
                    <object data="https://7perp.geopanda.com.mx/images/signature.svg" width="250" height="180"
                      class="lazy lazy-fade-in"> </object>
                  </p>
                  <div class="title item-label">
                    <p><i class="icon material-icons">gavel</i></p> VALIDEZ OFICIAL
                  </div>
                  <p class="text-align-justify">La firma digital tiene la misma validez y eficacia jurídica que el uso
                    de una
                    firma manuscrita u otra análoga que conlleve manifestación de voluntad, así como también la garantía
                    de
                    integridad y autenticidad del documento.</p>
                </div>
              </div>
              <div class="col-100 medium-33 margin-left">
                <section class="signature-component">
                  <canvas id="signature-pad" class="punteado" height="400"></canvas>
                  <button class="button button-fill clean-signature"><i class="icon material-icons">delete</i></button>
                </section>
              </div>
              <div class="col-100 medium-33 hide">
                <div class="row margin-top">
                  <div class="col-100 hide"><button id="save" class="button button-fill"><i
                        class="icon material-icons">save</i></button></div>
                  <div class="col-100 hide"> <button class="button button-fill clean-signature"><i
                        class="icon material-icons">delete</i></button></div>
                  <div class="col-100 hide"><button id="showPointsToggle" class="button button-fill">Show
                      points?</button></div>
                </div>
              </div>


            </div>





            <div class="row margin-top">
              <div class="col-50">
                <li>
                  <a class="button button-fill clean-data" href="#"><i class="icon material-icons">delete</i>
                    LIMPIAR</a>
                </li>
              </div>
              <div class="col-50">
                <li>
                  <a class="button button-fill convert-form-to-data" href="#"><i
                      class="icon material-icons">verified_user</i> REGISTRAR</a>
                </li>
              </div>


            </div>




          </ul>
        </form>

        <!-- <div class="block block-strong row">
          <div class="col"><a class="button button-fill convert-form-to-data" href="#">Get Data</a></div>
          <div class="col"><a class="button button-fill fill-form-from-data" href="#">Fill Form</a></div>
        </div> -->



      </div>

    </div>

  </div>
`
    }
    ;
}

framework7Component.id = 'e541bdf573';
export default framework7Component;