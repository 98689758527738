/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component() {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar navbar-large">
      <div class="navbar-bg"></div>
      <div class="navbar-inner navbar-inner-centered-title">
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="title sliding">7P ERP</div>
        <div class="right">
          <a href="#" class="link icon-only panel-open" data-panel="right">
            <i class="icon f7-icons if-not-md">more_vert</i>
            <i class="icon material-icons if-md">more_vert</i>
          </a>
        </div>
        <div class="title-large">
          <div class="title-large-text text-align-center">7P ERP</div>
        </div>
      </div>
    </div>
    <!-- Toolbar-->
    <div class="toolbar toolbar-bottom">
      <div class="toolbar-inner">
        <a href="#" class="link">Left Link</a>
        <a href="#" class="link">Right Link</a>
      </div>
    </div>
    <!-- Scrollable page content-->
    <div class="page-content">
      <div class="block block-strong">
        <p>This is an example of split view application layout, commonly used on tablets. The main approach of such kind of layout is that you can see different views at the same time.</p>

        <p>Each view may have different layout, different navbar type (dynamic, fixed or static) or without navbar.</p>

        <p>The fun thing is that you can easily control one view from another without any line of JavaScript just using "data-view" attribute on links.</p>
      </div>
      <div class="block-title">Navigation</div>
      <div class="list">
        <ul>
          <li>
            <a href="/about/" class="item-content item-link">
              <div class="item-inner">
                <div class="item-title">About</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/form/" class="item-content item-link">
              <div class="item-inner">
                <div class="item-title">Form</div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div class="block-title">Modals</div>
      <div class="block block-strong">
        <div class="row">
          <div class="col-50">
            <a href="#" class="button button-raised button-fill popup-open" data-popup="#my-popup">Popup</a>
          </div>
          <div class="col-50">
            <a href="#" class="button button-raised button-fill login-screen-open" data-login-screen="#my-login-screen">Login Screen</a>
          </div>
        </div>
      </div>

      <div class="block-title">Panels</div>
      <div class="block block-strong">
        <div class="row">
          <div class="col-50">
            <a href="#" class="button button-raised button-fill panel-open" data-panel="left">Left Panel</a>
          </div>
          <div class="col-50">
            <a href="#" class="button button-raised button-fill panel-open" data-panel="right">Right Panel</a>
          </div>
        </div>
      </div>

      <div class="list links-list">
        <ul>
          <li>
            <a href="/dynamic-route/blog/45/post/125/?foo=bar#about">Dynamic (Component) Route</a>
          </li>
          <li>
            <a href="/load-something-that-doesnt-exist/">Default Route (404)</a>
          </li>
          <li>
            <a href="/request-and-load/user/123456/">Request Data & Load</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '07212f8295';
export default framework7Component;