/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $el = _ref.$el,
      $theme = _ref.$theme,
      $on = _ref.$on;
  return function ($) {
    //$.noConflict();
    var $ = require('jquery');

    var dt = require('datatables.net'); //console.log(dt);


    var items = [];
    $on('pageInit', function () {
      $('#example_').DataTable({
        ajax: {
          url: 'https://7perp.geopanda.com.mx/php/admListas.7perp.php?lista=pagoList.f7.html_listaXcobrar',
          dataSrc: 'elementos'
        },
        columns: [{
          data: 'idinvoice'
        }, {
          data: 'estatus'
        }, {
          data: 'idinmueble'
        }, {
          data: 'inmueble'
        }, {
          data: 'inquilino_txt'
        }, {
          data: 'propietario'
        }],
        dom: '<"top"i><"item-content item-input"<"item-inner"<"item-input-wrap"f>>>rt<"data-table-footer"<"data-table-rows-select"l><"data-table-pagination"p>>' //dom: '<"top"i>rt<"bottom data-table-footer"flp><"clear">',

      }); //$("#example").DataTable();
      //getListaxCobrar();

      var virtualList = $f7.virtualList.create({
        // List Element
        el: $el.value.find('.virtual-list'),
        // Pass array with items
        items: items,
        // Custom search function for searchbar
        searchAll: function searchAll(query, items) {
          var found = [];

          for (var i = 0; i < items.length; i++) {
            if (items[i].idinvoice.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
          }

          return found; //return array with mathced indexes
        },
        // List item render
        renderItem: function renderItem(item) {
          return "\n                   <li>\n                    <a href=\"#\" class=\"item-link item-content\">\n                      <div class=\"item-inner\">\n                        <div class=\"item-title-row\">\n                          <div class=\"item-title\">".concat(item.idinvoice, "</div>\n                        </div>\n                        <div class=\"item-subtitle\">").concat(item.cantidad, "</div>\n                      </div>\n                    </a>\n                  </li>\n                 ");
        },
        // Item height
        height: $theme.ios ? 63 : $theme.md ? 73 : 77
      });

      function getListaxCobrar() {
        console.log("get Lista x cobrar");
        var formData = [];
        formData.lista = 'pagoList.f7.html_listaXcobrar';
        console.log(formData);
        $f7.dialog.progress();
        $f7.request.get('https://7perp.geopanda.com.mx/php/admListas.7perp.php', formData, "json").then(function (res) {
          console.log(res);

          if (res.data.code == 200) {
            if (typeof res.data.elementos != 'undefinded') {
              if (res.data.elementos.length > 0) {
                items = res.data.elementos;
                virtualList.replaceAllItems(items);
                virtualList.update();
              }
            }
          }

          $f7.dialog.close(); //$f7.dialog.alert(res.data.msj);
          //limpiaForm();
        }).catch(function (err) {
          $f7.dialog.alert(err.statusText);
          console.log(err.xhr);
          console.log(err.status);
          console.log(err.message);
        }).finally(function (e) {
          //limpiaForm();
          $f7.dialog.close();
        });
      }

      ;
    });
    return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
         <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title">Lista de invoices</div>
       
        <div class="subnavbar">
         <form data-search-container=".virtual-list" data-search-item="li" data-search-in=".item-title"
            class="searchbar searchbar-init">
            <div class="searchbar-inner">
              <div class="searchbar-input-wrap">
                <input type="search" placeholder="Search" />
                <i class="searchbar-icon"></i>
                <span class="input-clear-button"></span>
              </div>
              <span class="searchbar-disable-button if-not-aurora">Cancel</span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="searchbar-backdrop"></div>
    <div class="page-content">
      <div class="block block-strong">
          <!-- block content -->
          <div class="row">
          <div class="col-100 medium-33">
            <div class="card elevation-15 bg-color-pink">
                <div class="card-content card-content-padding">This is a simple card with plain text, but cards can also
                  contain their own header, footer, list view, image, or any other element.</div>
              </div>
          </div>
          <div class="col-100 medium-33">
            <div class="card elevation-15 bg-color-green">
                <div class="card-content card-content-padding">This is a simple card with plain text, but cards can also
                  contain their own header, footer, list view, image, or any other element.</div>
              </div>
          </div>
          <div class="col-100 medium-33">
            <div class="card elevation-15 bg-color-gray">
                <div class="card-content card-content-padding">This is a simple card with plain text, but cards can also
                  contain their own header, footer, list view, image, or any other element.</div>
              </div>
          </div>
        </div>

        </div>


<div class="row">
  <div class="col-100">
     <div class="card data-table">
  <table id="example">
    <thead>
      <tr>
        <th class="label-cell">Dessert (100g serving)</th>
        <th class="numeric-cell">Calories</th>
        <th class="numeric-cell">Fat (g)</th>
        <th class="numeric-cell">Carbs</th>
        <th class="numeric-cell">Protein (g)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="label-cell">Frozen yogurt</td>
        <td class="numeric-cell">159</td>
        <td class="numeric-cell">6.0</td>
        <td class="numeric-cell">24</td>
        <td class="numeric-cell">4.0</td>
      </tr>
    </tbody>
  </table>
  <!--<div class="data-table-footer">
    <div class="data-table-rows-select">
      Per page:
      <div class="input input-dropdown">
        <select>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="all">All</option>
        </select>
      </div>
    </div>
    <div class="data-table-pagination">
      <span class="data-table-pagination-label">1-5 of 10</span>
      <a href="#" class="link disabled">
        <i class="icon icon-prev color-gray"></i>
      </a>
      <a href="#" class="link">
        <i class="icon icon-next color-gray"></i>
      </a>
    </div>
  </div>-->
</div>
  </div>

</div>




<div class="row">
  <div class="col-95 margin-left">
          <div class="block block-strong">
          <table id="example_" class="cell-border compact stripe order-column" style="width:100%">
        <thead>
            <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Office</th>
                <th>Age</th>
                <th>Start date</th>
                <th>Salary</th>
            </tr>
        </thead>
        <tbody></tbody>
        <tfoot>
            <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Office</th>
                <th>Age</th>
                <th>Start date</th>
                <th>Salary</th>
            </tr>
        </tfoot>
    </table>
        </div>
  </div>
</div>
  




      <div class="list simple-list searchbar-not-found">
        <ul>
          <li>No se encontró</li>
        </ul>
      </div>
      <div class="list virtual-list media-list searchbar-found"></div>
    </div>
  </div>
`
    }
    ;
  }($);
}

framework7Component.id = '9f948277bc';
export default framework7Component;